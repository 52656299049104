import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import ReadingsMenu from "../components/readingsmenu"
import CartSummary from "../components/cartsummary"


const StyledContainer = styled.div` 
  width: 100%;
  margin: 2rem auto;
 `;




export default function ReadingsPage({ data }) {
  
  const readings = data.readings.nodes;
  
  return (
   
  <>
    <SEO title="Readings" />
    <StyledContainer>
    <CartSummary />
    <ReadingsMenu readings={readings} />
    </StyledContainer>
    
  </>
)
  }

  export const query = graphql` 
  query readingsQuery {
    readings: allSanityReadings(sort: {fields: code}) {
      nodes {
        id
        name
        code
        price
        pricecode
        spread
        desc1
        desc2
        desc3
         image {
          asset {
            gatsbyImageData(width: 600, height: 600, layout: FULL_WIDTH, placeholder: BLURRED, formats: [WEBP, AVIF, AUTO])
        }
      }
    }
    }
  }
`;  

