import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import CartContext from "../contexts/cartcontext"
import { useAuth } from "../contexts/authcontext"

const StyledShoppingCartSummaryZero = styled.div`
  visibility: hidden;
  width: 32rem;
  font-size: 1.8rem;
  margin-top: 1rem;
  padding: 1rem;
`

const StyledShoppingCartSummary = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  padding: 1rem;
  font-size: 1.6rem;
  background: var(--purple);

  border-radius: 1rem;
  border: thin solid var(--gold);
  box-shadow: 0 0 0.25rem 0.25rem rgba(65, 36, 86, 0.5);
  width: 30rem;
  :hover {
    background: var(--green);
  }
  a {
    color: var(--gold);
    cursor: pointer;
    :hover {
      color: var(--white);
    }
  }
`

export default function CartSummary() {
  const contxt = useContext(CartContext)
  const { currentUser } = useAuth()

  if (contxt.cart.length === 0) {
    return (
      <StyledShoppingCartSummaryZero>
        there is nothing in your cart
      </StyledShoppingCartSummaryZero>
    )
  } else if (contxt.cart.length >= 1) {
    return (
      <StyledShoppingCartSummary>
        {!currentUser ? (
          <Link to="/authorise">
            {" "}
            view the {contxt.cart.length} reading in your cart
          </Link>
        ) : (
          <Link to="/purchase">
            {" "}
            view the {contxt.cart.length} reading in your cart
          </Link>
        )}
      </StyledShoppingCartSummary>
    )
  } else
    return (
      <StyledShoppingCartSummary>
        {!currentUser ? (
          <Link to="/authorise">
            {" "}
            view the {contxt.cart.length} readings in your cart
          </Link>
        ) : (
          <Link to="/purchase">
            view the {contxt.cart.length} readings in your cart
          </Link>
        )}
      </StyledShoppingCartSummary>
    )
}
