import React, { useState, useContext } from "react"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import CartContext from "../contexts/cartcontext"
import Modal from "react-modal"

const ReadingsGrid = styled.div`
  display: grid;
  grid-template-columns: 32.5rem;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 2rem;
  margin: 0.5rem auto;
  padding: 1.5rem;
  border-radius: 1rem;
  background: transparent;
  @media (min-width: 850px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    grid-template-rows: auto;
  }
`

function ReadingItem({ reading }) {
  const image = getImage(reading.image.asset.gatsbyImageData)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const cntxt = useContext(CartContext)
  const addToCart = reading => {
    cntxt.setCart([...cntxt.cart, reading])
    closeModal()
  }

  const openModal = reading => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <>
      <Card style={{ width: `32.5rem`, height: `32.5rem` }}>
        <Card.Body className="m-0 p-0">
          <GatsbyImage
            image={image}
            alt={reading.name}
            style={{
              width: `32.3rem`,
              height: `29rem`,
              objectFit: `fill`,
              margin: `0 auto`,
            }}
          />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center m-0 p-0">
          <Button
            className="m-0 p-2"
            style={{ width: `32.2rem`, margin: `0 auto` }}
            type="button"
            onClick={() => {
              openModal(reading)
            }}
          >
            {reading.name}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        reading={reading}
        ariaHideApp={false}
      >
        <div className="modal-content">
          <div className="close-modal">
            <button onClick={closeModal}>X</button>
          </div>
          <div className="modal-view">
            <GatsbyImage
              image={reading.image.asset.gatsbyImageData}
              alt={reading.name}
              style={{ width: `30%`, margin: `0 auto` }}
            />
            <div className="modal-detail">
              <div className="modal-price">
                <h3>${`${reading.price}` / 100}</h3>
                <h6> incl. GST</h6>
              </div>
              <div className="modal-spread">Spread: {reading.spread}</div>
              <div className="modal-desc">{reading.desc1}</div>
              <div className="modal-desc">{reading.desc2}</div>
              <div className="modal-desc">{reading.desc3}</div>
            </div>
            <div className="modal-atc">
              <Button
                className="w-100 ATC"
                type="submit"
                onClick={() => addToCart(reading)}
              >
                Add To Cart{" "}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default function ReadingsMenu({ readings }) {
  return (
    <ReadingsGrid>
      {readings.map(reading => (
        <ReadingItem key={reading.id} reading={reading} />
      ))}
    </ReadingsGrid>
  )
}
